@import '_variables.scss';

.xm15 {
    margin-left: 15px;
}
.categories {
    margin: 0 0 0 15px;
}
.cid {
    margin: 0 15px 0 15px;
}
.wrapper {
    margin-bottom: 30px;
    a {
        color: var(--color-292929-f5f5f5);
        text-align: left;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
}
@media only screen and (min-width: 450px) {
    .xm15 {
        margin: 0 15px 10px 15px;
    }
    .cid {
        margin: 0 15px 10px 15px;
    }
    .categories {
        margin: 0 15px 10px 15px;
    }
    .wrapper {
        padding: 0;
        margin-bottom: 40px;
    }
}

@media only screen and (min-width: $tabletBreakpointMax) {
    .categories {
        margin: 0px 40px 10px 40px;
    }
    .cid {
        margin: 0px 40px 10px 40px;
    }
    .wrapper {
        padding: 0;
        margin-bottom: 40px;
    }
    .xm15 {
        margin: 0 40px 30px 30px;
    }
}

@media only screen and (min-width: $desktopBreakpoint) {
    .categories {
        margin: 0 0 40px 0;
    }
    .cid {
        margin: 0 0 40px 0;
    }
    .xm15 {
        margin: 0 0 40px 0;
    }
}
